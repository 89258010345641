import React, { FC } from 'react';

import SEO from 'src/components/organisms/common/SEO';
import YearlyPostList from 'src/components/organisms/post/YearlyPostList';
import usePosts from 'src/hooks/usePosts';
import Layout from 'src/components/templates/layout/Layout';

interface PropsType {
  year: number;
  data: GatsbyTypes.Query;
}

const YearlyPostListTemplate: FC<PropsType> = ({ year, data }) => {
  const posts = usePosts(data.allMarkdownRemark);
  const title = `Posts: ${year} (${posts.length})`;

  return (
    <Layout year={year}>
      <SEO title={title} />
      <YearlyPostList title={title} posts={posts} />
    </Layout>
  );
};

export default YearlyPostListTemplate;
