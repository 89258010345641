import React, { FC, memo } from 'react';
import { graphql } from 'gatsby';

import { ITemplateProps } from 'src/interfaces/template';
import YearlyPostListTemplate from 'src/components/templates/post/YearlyPostListTemplate';

interface PropsType
  extends ITemplateProps<{
    year: number;
  }> {}

const YearlyPostListPageTemplate: FC<PropsType> = memo(({ pageContext, data }) => {
  return <YearlyPostListTemplate year={pageContext.year} data={data as GatsbyTypes.Query} />;
});

export default YearlyPostListPageTemplate;

export const pageQuery = graphql`
  query YearlyPostListPage($from: Date, $to: Date) {
    allMarkdownRemark(
      limit: 1000
      sort: { order: DESC, fields: frontmatter___date }
      filter: { frontmatter: { published: { eq: true }, date: { gte: $from, lt: $to } } }
    ) {
      edges {
        node {
          excerpt(truncate: true, pruneLength: 300)
          frontmatter {
            title
            date
            slug
            tags
            series
            comments
            published
            cover {
              id
              absolutePath
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          id
        }
      }
    }
  }
`;
